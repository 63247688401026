import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      onClick: _ctx.handleClick,
      text: _ctx.btnDisplayWordsText
    }, null, 8, ["onClick", "text"]),
    (_ctx.isDisplayingWords)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.words(), (word, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("p", null, _toDisplayString(word), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}